import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import firebase from 'firebase/app';
import 'firebase/firestore';
import fs = firebase.firestore;

export interface AppState {
  hotels: Hotel[];
  hotel: Hotel;
  customers: Customer[];
  customer: Customer;
  currentUser: firebase.User | null;
  isSidebarOpen: boolean;
}

export interface Hotel {
  id: string;
  name: string;
  area: string;
  zip: string;
  address: string;
  receptionHours: string;
  tel: string;
  email: string;
  termUrl: string;
  covidMessage: string;
  department: string;
  PIC: string;
  PICTel: string;
  privacyPolicyUrl: string;
}

export const hotelConverter = {
  toFirestore(hotel: Hotel): fs.DocumentData {
    return {
      name: hotel.name,
      area: hotel.area,
      zip: hotel.zip,
      address: hotel.address,
      receptionHours: hotel.receptionHours,
      tel: hotel.tel,
      email: hotel.email,
      termUrl: hotel.termUrl,
      covidMessage: hotel.covidMessage,
      department: hotel.department,
      PIC: hotel.PIC,
      PICTel: hotel.PICTel,
      privacyPolicyUrl: hotel.privacyPolicyUrl
    };
  },
  fromFirestore(
    snapshot: fs.QueryDocumentSnapshot,
    options: fs.SnapshotOptions
  ): Hotel {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: data.name,
      area: data.area,
      zip: data.zip,
      address: data.address,
      receptionHours: data.receptionHours,
      tel: data.tel,
      email: data.email,
      termUrl: data.termUrl,
      covidMessage: data.covidMessage,
      department: data.department,
      PIC: data.PIC,
      PICTel: data.PICTel,
      privacyPolicyUrl: data.privacyPolicyUrl
    } as Hotel;
  }
};

export interface Customer {
  id: string;
  name: string;
  zip: string;
  address: string;
  email: string;
  tel: string;
  job: string;
  isCheckedIn: boolean;
  companions: string[];
  arrivalDate: Date;
  age: number;
  createdAt: Date;
}

export const customerConverter = {
  toFirestore(customer: Customer): fs.DocumentData {
    return {
      name: customer.name,
      zip: customer.zip,
      address: customer.address,
      email: customer.email,
      tel: customer.tel,
      job: customer.job,
      isCheckedIn: customer.isCheckedIn,
      companions: customer.companions,
      arrivalDate: customer.arrivalDate,
      age: customer.age,
      createdAt: customer.createdAt
    };
  },
  fromFirestore(
    snapshot: fs.QueryDocumentSnapshot,
    options: fs.SnapshotOptions
  ): Customer {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: data.name,
      zip: data.zip,
      address: data.address,
      email: data.email,
      tel: data.tel,
      job: data.job,
      isCheckedIn: data.isCheckedIn,
      companions: data.companions,
      arrivalDate: data.arrivalDate.toDate(),
      age: data.age,
      createdAt: data.createdAt.toDate()
    } as Customer;
  }
};

@Module({ dynamic: true, store, name: 'appModule' })
class App extends VuexModule implements AppState {
  public hotels = [] as Hotel[];
  public hotel = {} as Hotel;
  public customers = [] as Customer[];
  public customer = {} as Customer;
  public currentUser = null as firebase.User | null;
  public isSidebarOpen = true;

  @Action
  public closeSidebar() {
    this.CLOSE_SIDEBAR();
  }

  @Mutation
  private CLOSE_SIDEBAR() {
    this.isSidebarOpen = false;
  }


  @Action
  public toggleIsSidebarOpen() {
    this.TOGGLE_IS_SIDEBAR_OPEN();
  }

  @Mutation
  private TOGGLE_IS_SIDEBAR_OPEN() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  @Action
  public setHotel(hotel: Hotel) {
    this.SET_HOTEL(hotel);
  }

  @Mutation
  private SET_HOTEL(hotel: Hotel) {
    this.hotel = hotel;
  }

  @Action
  public setHotels(hotels: Hotel[]) {
    this.SET_HOTELS(hotels);
  }

  @Mutation
  private SET_HOTELS(hotels: Hotel[]) {
    this.hotels = hotels;
  }

  @Action
  public setCustomer(customer: Customer) {
    this.SET_CUSTOMER(customer);
  }

  @Mutation
  private SET_CUSTOMER(customer: Customer) {
    this.customer = customer;
  }

  @Action
  public setCustomers(customers: Customer[]) {
    this.SET_CUSTOMERS(customers);
  }

  @Mutation
  private SET_CUSTOMERS(customers: Customer[]) {
    this.customers = customers;
  }

  @Action
  public setCurrentUser(currentUser: firebase.User | null) {
    this.SET_CURRENT_USER(currentUser);
  }

  @Mutation
  private SET_CURRENT_USER(currentUser: firebase.User | null) {
    this.currentUser = currentUser;
  }
}
export const AppModule = getModule(App);
