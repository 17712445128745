import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { extend } from 'vee-validate';
import { required, email, confirmed, integer, image, size } from 'vee-validate/dist/rules';
extend('required', { ...required, message: "{_field_}は必須です" });
extend('email', { ...email, message: "正しいメールアドレスを入力してください" });
extend('confirmed', { ...confirmed, message: "{_field_}が異なります" });
extend('integer', { ...integer, message: "{_field_}は数値を入力してください" });
extend('image', { ...image, message: "{_field_}は画像を選択してください" });
extend('size', { ...size, message: "{_field_}のファイルサイズが大きすぎます" });
extend('tel', {
  message: '正しい電話番号を入力してください',
  validate(value) {
    if (value.match(/^[0-9\-.()+]+$/)) {
      return true;
    }
    return false;
  }
});
extend('zip', {
  message: '正しい郵便番号を入力してください',
  validate(value) {
    if (value.match(/^[0-9-]+$/)) {
      return true;
    }
    return false;
  }
});
extend('url', {
  message: '正しいURLを入力してください',
  validate(value) {
    if (value.match(/^https?:\/\/[\w /:%#$&?()~.=+-]+$/)) {
      return true;
    }
    return false;
  }
});
Vue.config.productionTip = false

import firebase from 'firebase'
const firebaseConfig = {
  apiKey: "AIzaSyD5rTCv0rIbZWYLgPE2OTsv8pfQCm8jANY",
  authDomain: "checkin-sr.firebaseapp.com",
  projectId: "checkin-sr",
  storageBucket: "checkin-sr.appspot.com",
  messagingSenderId: "272465915283",
  appId: "1:272465915283:web:fa24ff1a533c9e1dc44c66",
  measurementId: "G-XCJZLB54VJ"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'ja',
  messages: {
    ja: require('@/locale/ja.json'),
    en: require('@/locale/en.json'),
  }
});

import Notifications from 'vue-notification'
Vue.use(Notifications)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
