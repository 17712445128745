


















































import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import { AppModule } from '@/store/app';

@Component({
  components: {
    Sidebar,
  },
})
export default class HeaderComponent extends Vue {
  private get user() {
    return AppModule.currentUser;
  }

  $refs!: {
    sidebar: InstanceType<typeof Sidebar>;
  };

  private toggleSidebar(){
    this.$refs.sidebar.toggleSidebar();
  }
}
