










import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/app';
import firebase from 'firebase'

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
  },
})

export default class AppComponent extends Vue {
  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
    });
  }
}
