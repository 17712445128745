import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/manage'
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/hotel/:id',
    name: 'Hotel',
    component: () => import('../views/HotelView.vue')
  },
  {
    path: '/checkIn/:hotelId/:id',
    name: 'CheckIn',
    component: () => import('../views/CheckInView.vue')
  },
  {
    path: '/manage/',
    name: 'Customers',
    component: () => import('../views/CustomersView.vue')
  },
  {
    path: '/manage/customer/:id',
    name: 'Customer',
    component: () => import('../views/CustomerView.vue')
  },
  {
    path: '/manage/signin',
    name: 'SignIn',
    component: () => import('../views/SignInView.vue')
  },
  {
    path: '/manage/signup',
    name: 'SignUp',
    component: () => import('../views/SignUpView.vue')
  },
  {
    path: '/manage/payment',
    name: 'OperatorPayment',
    component: () => import('../views/OperatorPaymentView.vue')
  },
  {
    path: '/manage/setting',
    name: 'OperatorSetting',
    component: () => import('../views/OperatorSettingView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import { AppModule } from '@/store/app';
router.afterEach(()=>{
  if (window.innerWidth < 800) AppModule.closeSidebar();
});

export default router
