











































import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/app';

@Component
export default class Sidebar extends Vue {
  private get isSidebarOpen(){
    return AppModule.isSidebarOpen;
  }

  private changeLocale() {
    if(this.$i18n.locale=='ja'){
      this.$i18n.locale='en';
    }else if(this.$i18n.locale=='en'){
      this.$i18n.locale='ja';
    }
  }
  public toggleSidebar(){
    AppModule.toggleIsSidebarOpen();
  }
}
